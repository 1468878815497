import React, { useState, useEffect, } from "react";
import { useParams } from "react-router";
import axios from "axios";

import { credencialesNav, GetInfoCambioGuardia } from "../../services/services";

import Loading from "../00_Loading/Loading";
import Navegacion from "../00_Navigation/Index";
import NavSuperior from "../00_NavAux/Index";
import FormCambiaGuardia from "./01_CambiarGuardiaForm";

const initDate = async(Id) => {
    try {
        const dataGuardia = await axios({
            method:'GET',
            url: `${GetInfoCambioGuardia}/${Id}`,
            auth: {
                username: credencialesNav.username,
                password: credencialesNav.password
            }
        })

        return {
            data: dataGuardia.data.data
        }
        
    } catch (error) {
        return false
    }
}


const IndexCambioGuardiaDetalle = (props) => {
    const {Id} = useParams()

    const [guardia, setGuardia] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getData = async () => {
            const getGuardia = await initDate(Id)
            setGuardia(getGuardia.data)
            setLoading(false)
        }
        getData()
    },[Id])



    if(loading){
        return (
            <Loading />
        )
    }
    return (
        <div className='row'>
           
            <Navegacion  data={props.data.Navegacion} active={props.seccion} />
        
            <div className="col-md-10">
                <NavSuperior data={props.data.NavegacionSuperior} seccion={`Cambiar Guardia del  ${guardia.fecha}`} />
                <FormCambiaGuardia data={props.data.CambioDeGuardiaForm} detalles={guardia} />

            </div>
            
        </div>
    )
}

export default IndexCambioGuardiaDetalle
