import React from "react";
import LoginForm from "./01_Login";


const Login = (props) => {

    return (
        <LoginForm data={props.data} />

    )
}

export default  Login