import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';


const Cronograma = (props) => {

    const [Mes, setMes] = useState(0)
    const [Ano, setAno] = useState(0)



    return (
        <ContentGralCronograma>
            <TituloSeccion>
                {props.data.Titulo}
            </TituloSeccion>
            <SubtituloSeccion>
                {props.data.SubTitulo} <br />
                {props.data.SubTitulo2}
            </SubtituloSeccion>

                <ContentFormGetCronograma>
                    <form>
                        <div className="row">
                            <div className="col-md-3">
                                <LabelInput>
                                    {props.data.Ano}
                                </LabelInput>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <LabelIcono className='input-group-text' htmlFor='ano'>
                                            <i className='bi bi-calendar2'></i>
                                        </LabelIcono>
                                    </div>
                                    <InputFecha 
                                        className="custom-select p-2" 
                                        id="ano" 
                                        value={Ano}
                                        onChange={ (e) => setAno(e.target.value) }
                                    >
                                        <option>Año...</option>
                                        {
                                            props.data.Anos.map(ano => (
                                                <option key={ano.id} value={ano.id}>
                                                    {ano.valor}
                                                </option>
                                            ))
                                        }
                                    </InputFecha>
                                </div>

                            </div>
                            <div className="col-md-3">
                                <LabelInput>
                                    {props.data.Mes}
                                </LabelInput>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <LabelIcono className='input-group-text' htmlFor='mes'>
                                            <i className='bi bi-calendar2'></i>
                                        </LabelIcono>
                                    </div>
                                    <InputFecha 
                                        className="custom-select p-2" 
                                        id="mes" 
                                        name='mes'
                                        value={Mes}
                                        onChange={ (e) => setMes(e.target.value)} 
                                    >
                                        <option>Mes...</option>
                                        {
                                            props.data.Meses.map(mes => (
                                                <option key={mes.id} value={mes.id} >{mes.valor}</option>
                                            ))
                                        }
                                    </InputFecha>
                                </div>

                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-3">
                                {
                                    Mes === 0 || Ano === 0 ?
                                        <SinDatosDiv>
                                            {props.data.SinDatos}
                                        </SinDatosDiv>
                                        :

                                        <Link to={`/Cronogramas/${Mes}/${Ano}`}>
                                            <BotonVerCronograma>
                                                {props.data.BtnVerCrono}
                                            </BotonVerCronograma>
                                        </Link>
                                }


                            </div>
                            <div className="col-md-3">
                                <Link to='/Inicio'>
                                    <BotonVolverAlInicio>
                                        {props.data.VolverAlInicio}
                                    </BotonVolverAlInicio>
                                </Link>
                            </div>
                        </div>
                    </form>
                </ContentFormGetCronograma>


            
        </ContentGralCronograma>

    )
}

export default Cronograma

const ContentGralCronograma = styled.div`
    background: linear-gradient(180deg, #EEFFF0 0%, #FFFFFF 87.5%, rgba(255, 255, 255, 0) 96.87%);
    padding: 50px 30px;
`

const TituloSeccion = styled.p`
    font-family: var(--Pro-Text-Regular);
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 5%;
`

const SubtituloSeccion = styled.p`
    font-family: var(--Pro-Text-Regular);
    font-size: 1rem;
    margin-left: 5%;
    color: #00713B;
    margin-top: 10px;
`

const ContentFormGetCronograma  = styled.div`
    width: 90%;
    padding: 20px;
    margin:auto;
    margin-top: 5%;
`

const LabelInput = styled.p`
    font-family: var(--Pro-Text-Regular);
    color: #00713B;
    font-size: .75rem;
    margin-left: 1%;
    margin-bottom: 1% !important;
`

const LabelIcono = styled.label`
    background: none;
    color: #00713B;
    padding: 15px;
    border-radius: 0px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-left:  1px solid #00713B;
    border-top:  1px solid #00713B;
    border-bottom: 1px solid #00713B;
    border-right: none;

`

const InputFecha = styled.select`
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    border-right:  1px solid #00713B;
    border-top:  1px solid #00713B;
    border-bottom: 1px solid #00713B;
    border-left: none;
    width: 50%;
    padding: 15px 10px !important;
    transition: all .5s linear !important;
    color: #333C37;
    font-family: var(--Pro-Text-Regular);
    font-size: .75rem;

    &::placeholder {
        font-family: var(--Pro-Text-Regular);
        color: #808080 !important;
        font-size: .9rem;
        padding-left: 10px;
    }

    &:focus{
        color: #212529;
        background-color: #fff;
        outline: 0;
        box-shadow: none;
    }
`

const SinDatosDiv = styled.div`
    background: #00713B;
    font-family: var(--Pro-Text-Regular);
    color: #fff;
    width: 90%;
    text-align: center;
    font-size: .8rem;
    padding: 15px;
    border-radius: 10px;
`

const BotonVerCronograma = styled.button`
    background: #00713B;
    font-family: var(--Pro-Text-Regular);
    color: #fff;
    width: 90%;
    text-align: center;
    font-size: .8rem;
    padding: 15px;
    border: none;
    border-radius: 10px;
    transition: all .5s linear;
    &:hover{
        transform: scale(.9);
        background-color: #BFE8C3;
        color: #38A342;
    }

`

const BotonVolverAlInicio = styled.button`
    background: #BFE8C3;
    font-family: var(--Pro-Text-Regular);
    color: #00713B;
    width: 90%;
    padding: 15px ;
    border: none;
    border-radius: 10px;
    transition: all .5s linear;
    &:hover{
        transform: scale(.9);
        background-color: #BFE8C3;
        color: #38A342;
    }

`

