import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const DetalleGuardia = (props) => {
    return (
        <ContentGralGuardia>
            <TituloSeccion>
                {props.data.Titulo}
            </TituloSeccion>
            <SubtituloSeccion>
                {props.data.SubTitulo} <br />
                {props.data.SubTitulo2}
            </SubtituloSeccion>
            <br />
            <ContentDetalleGuardia>
                <LabelDetalleGuardia>
                    {props.data.LabelFecha}
                </LabelDetalleGuardia>
                <ContentInfoGuardia>
                    <InfoGuardia>
                        {props.guardia.fecha}
                    </InfoGuardia>
                    
                </ContentInfoGuardia>
            </ContentDetalleGuardia>

            <ContentDetalleGuardia>
                <LabelDetalleGuardia>
                    {props.data.LabelNombre}
                </LabelDetalleGuardia>
                <ContentInfoGuardia className='row m-1'>
                    <div className="col-md-8 align-self-center">
                        <InfoGuardia>
                            {props.guardia.detalle.Farmacias.Nombre}
                        </InfoGuardia>
                    </div>
                    <div className="col-md-4">
                        <Link to={`/Farmacias/${props.guardia.detalle.Farmacias.Id}`}>
                            <BotonInfoFarmacia>
                                Ver Farmacia
                            </BotonInfoFarmacia>
                        </Link>
                    </div>
                </ContentInfoGuardia>
                
            </ContentDetalleGuardia>

            <ContentDetalleGuardia>
                <LabelDetalleGuardia>
                    {props.data.LabelTipoGuardia}
                </LabelDetalleGuardia>
                <ContentInfoGuardia>
                    <InfoGuardia>
                        {props.guardia.detalle.TipoGuardia.Nombre} Hs.
                    </InfoGuardia>
                    
                </ContentInfoGuardia>
            </ContentDetalleGuardia>
            <br />
            <hr />
            <ContentDetalleGuardia>
                <div className="row px-3 mt-4">
                    <div className="col-md-4">
                        <Link to={`/CambiarGuardia/${props.guardia.detalle.Id}`}>
                            <BotonEditarGuardia type='submit'>
                                {props.data.BtnCambiaGuardia}
                            </BotonEditarGuardia>
                        </Link>
                    </div>
                    <div className="col-md-4">
                        <Link to='/Inicio'>
                            <BotonVolverAlInicio>
                                {props.data.BtnVolverInicio}
                            </BotonVolverAlInicio>
                        </Link>
                    </div>
                    <div className="col-md-4">
                        <Link to='/Farmacias'>
                            <BotonIrAFarmacias>
                                {props.data.BtnVerFarmacias}
                            </BotonIrAFarmacias>
                        </Link>
                    </div>
                </div>
            </ContentDetalleGuardia>

        </ContentGralGuardia>
    )
}

export default DetalleGuardia

const ContentGralGuardia = styled.div`
    background: linear-gradient(180deg, #EEFFF0 0%, #FFFFFF 87.5%, rgba(255, 255, 255, 0) 96.87%);
    padding: 50px 30px;
`

const TituloSeccion = styled.p`
    font-family: var(--Pro-Text-Regular);
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 5%;
`

const SubtituloSeccion = styled.p`
    font-family: var(--Pro-Text-Regular);
    font-size: 1rem;
    margin-left: 5%;
    color: #00713B;
    margin-top: 10px;
`

const ContentDetalleGuardia = styled.div`
    width: 80% !important;
    margin-top: 2% !important;
    margin-left: 5% !important;
`

const LabelDetalleGuardia = styled.p`
    font-family: var(--Pro-Text-Regular);
    color: #00713B;
    font-size: .75rem;
    margin-left: 1%;
    margin-bottom: 1% !important;
`

const ContentInfoGuardia = styled.div`
    background: #fff;
    border: 1px solid #00713B;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 20px 20px !important;
    width: 50% !important;
`

const InfoGuardia = styled.p`
    color: #333C37;
    font-family: var(--Pro-Text-Regular);
    font-size: 1rem;

`

const BotonInfoFarmacia = styled.button`
    background-color: #BFE8C3;
    width: 100%;
    border-radius: 10px;
    border:none;
    padding: 5px 10px;
    color: #fff;
    font-family: var(--Pro-Text-Regular);
    font-size: .8rem;
    transition: all .5s linear;

    &:hover{
        transform: scale(.9);
        background-color: #BFE8C3;
        color: #38A342;
    }

`



const BotonEditarGuardia = styled.button`
    background: #00713B;
    font-family: var(--Pro-Text-Regular);
    color: #fff;
    width: 80%;
    margin-left: 10%;
    padding: 10px 33px;
    border: none;
    border-radius: 12px;
    transition: all .5s linear;
    &:hover{
        transform: scale(.9);
        background-color: #BFE8C3;
        color: #38A342;
    }

`

const BotonVolverAlInicio = styled.button`
    background: #BFE8C3;
    font-family: var(--Pro-Text-Regular);
    color: #00713B;
    width: 80%;
    margin-left: 10%;
    padding: 10px 33px;
    border: none;
    border-radius: 12px;
    transition: all .5s linear;
    &:hover{
        transform: scale(.9);
        background-color: #BFE8C3;
        color: #38A342;
    }

`

const BotonIrAFarmacias = styled.button`
    background: #FFFFFF;
    font-family: var(--Pro-Text-Regular);
    color: #00713B;
    width: 80%;
    margin-left: 10%;
    padding: 10px 33px;
    border: 1px solid #BFE8C3;
    border-radius: 12px;
    transition: all .5s linear;
    &:hover{
        transform: scale(.9);
        background-color: #BFE8C3;
        color: #38A342;
    }
`