import React from "react";
import styled from 'styled-components';

import CardsPanel from "./02_CardsPanel";

const PanelInicio = (props) => {
    return (
        <ContentGralInfoPanel>
            <TituloSeccion>
                {props.data.Titulo}
            </TituloSeccion>

            <SubtituloSeccion>
                {props.data.Subtitulo}
            </SubtituloSeccion>

            <ContentDetalleInfoPanel>
            <div className="row">
                {
                    props.guardias.map(guardia => (
                        <CardsPanel key={guardia.Id} guardia={guardia} />
                    ))
                }

                </div>

            </ContentDetalleInfoPanel>
        </ContentGralInfoPanel>

    )
}

export default PanelInicio

const ContentGralInfoPanel = styled.div`
    background: linear-gradient(180deg, #EEFFF0 0%, #FFFFFF 87.5%, rgba(255, 255, 255, 0) 96.87%);
    padding: 50px 30px;
`

const TituloSeccion = styled.p`
    font-family: var(--Pro-Text-Regular);
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 5%;
`

const SubtituloSeccion = styled.p`
    font-family: var(--Pro-Text-Regular);
    font-size: 1rem;
    margin-left: 5%;
    color: #00713B;
    margin-top: 10px;
`

const ContentDetalleInfoPanel = styled.div`
    width: 100%;
    margin:auto;
    margin-top: 5%;
`