import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';

import { credencialesNav, EditGuardia } from "../../services/services";
import Loading from "../00_Loading/Loading";
import ModalExito from "../00_Modal/01_ModalExito";
import ModalError from "../00_Modal/02_ModalError";

const CambiarGuardiaForm = (props) => {

    const [farmaciaSelect, setFarmaciaSelect] = useState(0)
    const idGuardia = props.detalles.guardia.Id 
    const idFarmacia =  props.detalles.guardia.Farmacias.Id

    const [loading, setLoading] = useState(false)
    const [modalExito, setModalExito] = useState(false)
    const [modalError, setModalError] = useState(false)

    const onSubmit = async(e) => {
        e.preventDefault()
        setLoading(true)
        const body = {
            IdFarmaciaEntra: farmaciaSelect,
            IdGuardia: idGuardia,
            "Motivo": "",
        }

        try {
            const data = await axios({
                method: 'PUT',
                url: `${EditGuardia}`,
                data: body,
                auth: {
                    username: credencialesNav.username,
                    password: credencialesNav.password
                }
            })
    
            if(data.data.status === 'OK'){
                setLoading(false)
                setModalExito(true)
            }else{
                setLoading(false)
                setModalError(true)
            }
            
        } catch (error) {
            setLoading(false)
            setModalError(true)
        }


    }

    if(loading){
        return(
            <Loading />
        )
    }

    return (
        <ContentGralFormCambioGuardia>
            <TituloSeccion>
                {props.data.Titulo}
            </TituloSeccion>
            <SubtituloSeccion>
                {props.data.SubTitulo}
                <br />
                {props.data.SubTitulo2}
            </SubtituloSeccion>
            <br />
            <form onSubmit={onSubmit}>
                <ContentDetalleGuardia>
                    <LabelDetalleGuardia>
                        {props.data.LabelFecha}
                    </LabelDetalleGuardia>
                    <ContentInfoGuardia>
                        <InfoGuardia>
                        {props.detalles.fecha}
                        </InfoGuardia>
                        
                    </ContentInfoGuardia>
                </ContentDetalleGuardia>

                <ContentDetalleGuardia>
                    <LabelDetalleGuardia>
                        {props.data.LabelTipoGuardia}
                    </LabelDetalleGuardia>
                    <ContentInfoGuardia>
                        <InfoGuardia>
                            {props.detalles.guardia.TipoGuardia.Nombre}
                        </InfoGuardia>
                    </ContentInfoGuardia>
                </ContentDetalleGuardia>
            
                <ContentDetalleGuardia>
                    <LabelDetalleGuardia>
                        {props.data.LabelFarmacia}
                    </LabelDetalleGuardia>
                    <ContentInfoGuardia className='row m-1'>
                        <div className="col-md-8 align-self-center">
                            <InfoGuardia>
                                {props.detalles.guardia.Farmacias.Nombre}
                            </InfoGuardia>
                        </div>
                        <div className="col-md-4">
                            <Link to={`/Farmacias/${idFarmacia}`}>
                                <BotonInfoFarmacia>
                                    Ver Farmacia
                                </BotonInfoFarmacia>
                            </Link>
                        </div>
                    </ContentInfoGuardia>  
                </ContentDetalleGuardia>
                <ContentDetalleGuardia>
                    <LabelDetalleGuardia>
                        {props.data.LabelSelectFarmacia}
                    </LabelDetalleGuardia>
                    <ContentInfoGuardia>
                        <InputSelect
                            value={farmaciaSelect}
                            onChange={ (e) => setFarmaciaSelect(e.target.value)} 
                        >
                            <option>Seleccioná una farmacia</option>
                            {
                                props.detalles.farmacias.map(unaFarmacia => (
                                    <option value={unaFarmacia.Id} key={unaFarmacia.Id}>{unaFarmacia.Nombre}</option>
                                ))
                            }
                        </InputSelect>

                    </ContentInfoGuardia>
                </ContentDetalleGuardia>
                <br />
                <hr />
                <ContentDetalleGuardia>
                    <div className="row px-3 mt-4">
                        <div className="col-md-4">
                            {
                                farmaciaSelect === 0 ?
                                    <BotonSelectFarmacias>
                                        {props.data.LabelSelectFarmacia}
                                    </BotonSelectFarmacias>
                                :

                                <BotonEditarGuardia  type='submit'>
                                    {props.data.BtnGuardar}
                                </BotonEditarGuardia>
                            }
                        </div>
                        <div className="col-md-4">
                            <Link to='/Inicio'>
                                <BotonVolverAlInicio>
                                    {props.data.BtnVolverInicio}
                                </BotonVolverAlInicio>
                            </Link>
                        </div>
                        <div className="col-md-4">
                            <Link to='/Cambios'>
                                <BotonIrAFarmacias>
                                    {props.data.BtnBuscarOtraGuardia}
                                </BotonIrAFarmacias>
                            </Link>
                        </div>
                    </div>
                </ContentDetalleGuardia>

            </form>

            <React.StrictMode>
                <ModalExito
                    Modal = {modalExito}
                    Titulo = {`¡Exito!`}
                    Subtitulo = {`Datos de Guardia Editados`}
                    Info = {`Se han editado los datos de la Guardia del `}
                    Detail = {props.detalles.fecha}
                    TextoLink = {`Relizar otro cambio`}
                    Link ={`/Cambios`}
                />
            </React.StrictMode>

            <React.StrictMode>
                <ModalError
                    Modal = {modalError}
                    toggle = {(e) => setModalError(!modalError)}
                    Titulo = {`¡Error!`}
                    Subtitulo = {`Error al intentar editar los datos de la guardia del`}
                    Detail = {props.detalles.fecha}
                    Info = {`Vuelva a intentarlo en unos minutos, Si el problema pesiste contactese con su desarrollador.`}
                    TextoLink = {`Relizar otro cambio`}
                    Link ={`/Cambios`}
                />
            </React.StrictMode>

        </ContentGralFormCambioGuardia>
    )
}

export default CambiarGuardiaForm

const ContentGralFormCambioGuardia = styled.div`
    background: linear-gradient(180deg, #EEFFF0 0%, #FFFFFF 87.5%, rgba(255, 255, 255, 0) 96.87%);
    padding: 50px 30px;
`

const TituloSeccion = styled.p`
    font-family: var(--Pro-Text-Regular);
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 5%;
`

const SubtituloSeccion = styled.p`
    font-family: var(--Pro-Text-Regular);
    font-size: 1rem;
    margin-left: 5%;
    color: #00713B;
`

const ContentDetalleGuardia = styled.div`
    width: 80% !important;
    margin-top: 2% !important;
    margin-left: 5% !important;
`

const LabelDetalleGuardia = styled.p`
    font-family: var(--Pro-Text-Regular);
    color: #00713B;
    font-size: .75rem;
    margin-left: 1%;
    margin-bottom: 1% !important;
`

const ContentInfoGuardia = styled.div`
    background: #fff;
    border: 1px solid #00713B;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 20px 20px !important;
    width: 50% !important;
`

const InfoGuardia = styled.p`
    color: #333C37;
    font-family: var(--Pro-Text-Regular);
    font-size: 1rem;

`

const BotonInfoFarmacia = styled.button`
    background-color: #BFE8C3;
    width: 100%;
    border-radius: 10px;
    border:none;
    padding: 5px 10px;
    color: #fff;
    font-family: var(--Pro-Text-Regular);
    font-size: .8rem;
    transition: all .5s linear;

    &:hover{
        transform: scale(.9);
        background-color: #BFE8C3;
        color: #38A342;
    }

`



const BotonEditarGuardia = styled.button`
    background: #00713B;
    font-family: var(--Pro-Text-Regular);
    color: #fff;
    width: 80%;
    margin-left: 10%;
    padding: 10px 33px;
    border: none;
    border-radius: 12px;
    transition: all .5s linear;
    font-size: .9rem;
    &:hover{
        transform: scale(.9);
        background-color: #BFE8C3;
        color: #38A342;
    }

`


const InputSelect = styled.select`
    border: none;
    width: 100%;
    padding: 0px !important;
    transition: all .5s linear !important;
    color: #333C37;
    font-family: var(--Pro-Text-Regular);
    font-size: 1rem;

    &::placeholder {
        font-family: var(--Pro-Text-Regular);
        color: #808080 !important;
        font-size: .9rem;
        padding-left: 10px;
    }

    &:focus{
        color: #212529;
        background-color: #fff;
        outline: 0;
        box-shadow: none;
    }
`

const BotonVolverAlInicio = styled.button`
    background: #BFE8C3;
    font-family: var(--Pro-Text-Regular);
    color: #00713B;
    width: 80%;
    margin-left: 10%;
    padding: 10px 33px;
    border: none;
    border-radius: 12px;
    transition: all .5s linear;
    font-size: .9rem;
    &:hover{
        transform: scale(.9);
        background-color: #BFE8C3;
        color: #38A342;
    }

`

const BotonIrAFarmacias = styled.button`
    background: #FFFFFF;
    font-family: var(--Pro-Text-Regular);
    color: #00713B;
    width: 80%;
    margin-left: 10%;
    padding: 10px 33px;
    border: 1px solid #BFE8C3;
    border-radius: 12px;
    transition: all .5s linear;
    font-size: .9rem;
    &:hover{
        transform: scale(.9);
        background-color: #BFE8C3;
        color: #38A342;
    }
`

const BotonSelectFarmacias = styled.div`
    background: #FFFFFF;
    font-family: var(--Pro-Text-Regular);
    color: #00713B;
    width: 100%;
    padding: 10px 33px;
    border: 1px solid #BFE8C3;
    border-radius: 12px;
    transition: all .5s linear;
    font-size: .9rem;
    text-align: center;

`