import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import axios from "axios";

import { credencialesNav, GetDataFarmacia } from "../../services/services";

import Loading from "../00_Loading/Loading";
import ModalExito from "../00_Modal/01_ModalExito";
import ModalError from "../00_Modal/02_ModalError";

const DetalleFarmacia = (props) => {

    const [Nombre, setNombre] = useState(props.farmacia.Nombre)
    const [Direccion, setDireccion] = useState(props.farmacia.Direccion)
    const [Telefono, setTelefono] = useState(props.farmacia.Telefono)
    const [Contacto, setContacto] = useState(props.farmacia.Contacto)
    const [Ubicacion, setUbicacion] = useState(props.farmacia.Ubicacion)
    const [isReadonly, setIsReadonly] = useState(true);

    const [loading, setLoading] = useState(false)
    const [modalExito, setModalExito] = useState(false)
    const [modalError, setModalError] = useState(false)

    const onSubmit = async(e) => {
        e.preventDefault()
        setLoading(true)
        const body = {
            Direccion,
            Telefono,
            Ubicacion,
            Contacto,
            "Observaciones": "",
            "Instagram": "",
            "Facebook": ""
        }

        try {
            const data = await axios({
                method: 'PUT',
                url: `${GetDataFarmacia}/${props.farmacia.Id}`,
                data: body,
                auth: {
                    username: credencialesNav.username,
                    password: credencialesNav.password
                }
            })
    
            if(data.data.status === 'OK'){
                setLoading(false)
                setModalExito(true)
            }else{
                setLoading(false)
                setModalError(true)
            }
            
        } catch (error) {
            setLoading(false)
            setModalError(true)
        }


    }

    if(loading){
        return (
            <Loading />
        )
    }


    return (
        <ContentGralFarmacias>
            <TituloSeccion>
                {props.data.Titulo}
            </TituloSeccion>
            <SubtituloSeccion>
                {props.data.Subtitulo} <br />
                {props.data.Subtitulo2}
            </SubtituloSeccion>
            <ContentDetalleFarmacias>

                <form onSubmit={onSubmit}>
                    <div className='mb-3'>
                        <LabelInput>
                            {props.data.Nombre}
                        </LabelInput>
                        <InputFarmacia 
                            className='form-control'
                            type='text'
                            name='Nombre'
                            value={Nombre}
                            placeholder={props.data.Placeholder}
                            onChange={ (e) => setNombre(e.target.value)}
                            readOnly
                            onFocus={(e) => setIsReadonly(false)}
                            required
                        />
                    </div>
                    <div className='mb-3'>
                        <LabelInput>
                            {props.data.Direccion}
                        </LabelInput>
                        <InputFarmacia 
                            className='form-control'
                            type='text'
                            name='Direccion'
                            value={Direccion}
                            placeholder={props.data.Placeholder}
                            onChange={ (e) => setDireccion(e.target.value)}
                            required={true}
                            readOnly={isReadonly}
                            onFocus={(e) => setIsReadonly(false)}
                        />

                    </div>
                    <div className='mb-3'>
                        <LabelInput>
                            {props.data.Telefono}
                        </LabelInput>
                        <InputFarmacia 
                            className='form-control'
                            type='text'
                            name='Telefono'
                            value={Telefono}
                            placeholder={props.data.Placeholder}
                            onChange={ (e) => setTelefono(e.target.value)}
                            required={true}
                            readOnly={isReadonly}
                            onFocus={(e) => setIsReadonly(false)}
                        />
                    </div>
                    <div className='mb-3'>
                        <LabelInput>
                            {props.data.Contacto}
                        </LabelInput>
                        <InputFarmacia 
                            className='form-control'
                            type='text'
                            name='Contacto'
                            value={Contacto}
                            placeholder={props.data.PlaceholderSinDatos}
                            onChange={ (e) => setContacto(e.target.value)}
                            readOnly={isReadonly}
                            onFocus={(e) => setIsReadonly(false)}
                        />
                    </div>
                    <div className='mb-3'>
                        <LabelInput>
                            {props.data.VerUbicacion}
                        </LabelInput>
                        <div className="row px-3">
                            <div className="col-md-10 ">
                                <InputFarmacia 
                                    className='form-control'
                                    type='text'
                                    name='Contacto'
                                    value={Ubicacion}
                                    placeholder={props.data.PlaceholderSinDatos}
                                    onChange={ (e) => setUbicacion(e.target.value)}
                                    required={true}
                                    readOnly={isReadonly}
                                    onFocus={(e) => setIsReadonly(false)}
                                />

                            </div>
                            <div className="col-md-2 align-self-center">
                                <LinkMapa href={props.farmacia.Ubicacion} target='_blank' className='btn'  rel="noreferrer">    
                                    {props.data.Mapa}
                                </LinkMapa>
                            </div>
                        </div>

                    </div>
                    <br />
                    <hr />
                    <div className="row px-3 mt-4">
                        <div className="col-md-4">
                            {
                                isReadonly === true ? 
                                    <TextoSinCambios>
                                        {props.data.SinCambios}
                                    </TextoSinCambios>
                                    :
                                    <BotonEditarGuardia type='submit'>
                                        {props.data.Guardar}
                                    </BotonEditarGuardia>

                            }

                        </div>
                        <div className="col-md-4">
                            <Link to='/Inicio'>
                                <BotonVolverAlInicio>
                                    {props.data.VolverInicio}
                                </BotonVolverAlInicio>
                            </Link>
                        </div>
                        <div className="col-md-4">
                            <Link to='/Farmacias'>
                                <BotonIrAFarmacias>
                                    {props.data.ListadoFarmacias}
                                </BotonIrAFarmacias>
                            </Link>
                        </div>
                    </div>
                </form>


            </ContentDetalleFarmacias>


            <React.StrictMode>
                <ModalExito
                    Modal = {modalExito}
                    Titulo = {`¡Exito!`}
                    Subtitulo = {`Datos de Farmacia Editada`}
                    Info = {`Se han editado los datos de la Farmacia `}
                    Detail = {Nombre}
                    TextoLink = {`Ir a ver Farmacias`}
                    Link ={`/Farmacias`}
                />
            </React.StrictMode>

            <React.StrictMode>
                <ModalError
                    Modal = {modalError}
                    toggle = {(e) => setModalError(!modalError)}
                    Titulo = {`¡Error!`}
                    Subtitulo = {`Error al intentar editar los datos de la Farmacia `}
                    Detail = {Nombre}
                    Info = {`Vuelva a intentarlo en unos minutos, Si el problema pesiste contactese con su desarrollador.`}
                    TextoLink = {`Ir a ver Farmacias`}
                    Link ={`/Farmacias`}
                />
            </React.StrictMode>
        </ContentGralFarmacias>
    )
}

export default DetalleFarmacia

const ContentGralFarmacias = styled.div`
    background: linear-gradient(180deg, #EEFFF0 0%, #FFFFFF 87.5%, rgba(255, 255, 255, 0) 96.87%);
    padding: 50px 30px;
`

const TituloSeccion = styled.p`
    font-family: var(--Pro-Text-Regular);
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 5%;
`

const SubtituloSeccion = styled.p`
    font-family: var(--Pro-Text-Regular);
    font-size: 1rem;
    margin-left: 5%;
    color: #00713B;
    margin-top: 10px;
`

const ContentDetalleFarmacias = styled.div`
    width: 80%;
    padding: 20px;
    margin-top: 2%;
    flex: none;
`

const LabelInput = styled.p`
    font-family: var(--Pro-Text-Regular);
    color: #00713B;
    font-size: .75rem;
    margin-left: 1%;
    margin-bottom: 1% !important;
`

const InputFarmacia = styled.input`
    border-radius: 10px !important;
    width: 100%;
    padding: 15px;
    transition: all .5s linear !important;
    color: #333C37;
    font-family: var(--Pro-Text-Regular);
    font-size: .9rem;

    &::placeholder {
        font-family: var(--Pro-Text-Regular);
        color: #808080 !important;
        font-size: .9rem;
        padding-left: 10px;
    }

    &:focus{
        color: #212529;
        background-color: #fff;
        border-color: #00713B;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgb(51 60 55 / 25%)
    }
`

const LinkMapa = styled.a`
    width: 90%;
    margin-left: 10% !important;
    background: #BFE8C3;
    font-family: var(--Pro-Text-Regular);
    border-radius: 10px !important;
    font-size: .75rem;
    padding: 15px !important;
    font-weight: bold;
    font-size: .8rem;
    color: #38A342 !important;
    transition: all .5s linear;

    &:hover{
        transform: scale(.9);
        background-color: #BFE8C3;
        color: #38A342;
    }
`

const TextoSinCambios = styled.div`
    background: #00713B;
    font-family: var(--Pro-Text-Regular);
    color: #fff;
    width: 80%;
    margin-left: 10%;
    padding: 10px 33px;
    border: none;
    border-radius: 12px;
    text-align: center;
`

const BotonEditarGuardia = styled.button`
    background: #00713B;
    font-family: var(--Pro-Text-Regular);
    color: #fff;
    width: 80%;
    margin-left: 10%;
    padding: 10px 33px;
    border: none;
    border-radius: 12px;
    transition: all .5s linear;
    &:hover{
        transform: scale(.9);
        background-color: #BFE8C3;
        color: #38A342;
    }

`

const BotonVolverAlInicio = styled.button`
    background: #BFE8C3;
    font-family: var(--Pro-Text-Regular);
    color: #00713B;
    width: 80%;
    margin-left: 10%;
    padding: 10px 33px;
    border: none;
    border-radius: 12px;
    transition: all .5s linear;
    &:hover{
        transform: scale(.9);
        background-color: #BFE8C3;
        color: #38A342;
    }

`

const BotonIrAFarmacias = styled.button`
    background: #FFFFFF;
    font-family: var(--Pro-Text-Regular);
    color: #00713B;
    width: 80%;
    margin-left: 10%;
    padding: 10px 33px;
    border: 1px solid #BFE8C3;
    border-radius: 12px;
    transition: all .5s linear;
    &:hover{
        transform: scale(.9);
        background-color: #BFE8C3;
        color: #38A342;
    }
`
