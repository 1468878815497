import styled from 'styled-components';

import CardGuardia from "./02_CardGuardia";

const CardPanel = (props) => {
    return (
        <div className="col-md-4 mt-3 mb-3">
            <ContentFecha>        
                {props.guardia.fecha}
            </ContentFecha>


            <div className="row mt-4">
                {
                    props.guardia.guardias.map(guardia => (

                        <CardGuardia key={guardia.Id} detalle={guardia} />
                    ))
                }

            </div>
            <hr />

   
        </div>
    )
}

export default CardPanel

const ContentFecha = styled.div`
    background: #00713B;
    box-shadow: 0px 4px 18px 0px #00000040;
    border-radius: 31px;
    padding: 10px;
    font-family: var(--Pro-Text-Regular);
    font-size: .8rem;
    color: #fff;
    text-align: center;
    width: 90%;
    margin: auto;
`