import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Navegacion from "../00_Navigation/Index";
const Error = (props) => {
    return (
        <div className="row">


            <Navegacion data={props.data.Navegacion} />

            <div className="col-md-10">
                <ContentMensajeError>
                    <ContentMensaje>
                        <MensajeErrorUps>
                            Ups! 
                        </MensajeErrorUps>
                        <MensajeError>
                            Al parecer lo que estás buscando no se encuentra disponible.
                        </MensajeError>

   
                        <Link   to='/' >
                            <BotonRecargar>
                                Ir al Inicio
                            </BotonRecargar>
                        </Link>


                    </ContentMensaje>

                </ContentMensajeError>

            </div>
        </div>
    )
}

export default Error

const ContentMensajeError = styled.div`
    background:linear-gradient(180deg, #EEFFF0 0%, #FFFFFF 87.5%, rgba(255, 255, 255, 0) 96.87%); 
    margin: auto;
    height: 100vh;
    display: flex;
    align-items: center;
`

const ContentMensaje = styled.div`
    width: 70%;
    margin: auto;
    text-align: center;
`

const MensajeErrorUps = styled.p`
    font-size: 1.5rem;
    text-align: center;
    font-family: var(--Pro-Text-Regular);
    font-weight: bold;
` 

const MensajeError = styled.p`
    font-size: 1.5rem;
    text-align: center;
    font-family: var(--Pro-Text-Regular);
` 

const BotonRecargar = styled.button`
    margin-top: 1rem;
    background: #00713B;
    padding: 20px 33px;
    border: none;
    border-radius: 12px;
    font-family: var(--Pro-Text-Regular);
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;    
    line-height: 1rem;
    color: #fff;
    transition: all .5s linear;

    &:hover{
        transform: scale(.9);
    }
`