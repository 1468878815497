import ErrorApi from "./01_ErrorApi";
import Error404 from "./02_Error404";

const Errors = (props) => {

    const error = props.Api

    if(!error){
        return (
            <ErrorApi data={props.data} />
        )
    }

    if(error){
        return(
            <Error404 data={props.data} />
        )
    }
 
}

export default Errors