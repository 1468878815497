import React, {useState, useEffect} from 'react'
import axios from "axios";

import { credencialesNav, GetDetalleGuardiasByFarmacia } from "../../services/services";
import Navegacion from "../00_Navigation/Index";
import NavSuperior from "../00_NavAux/Index";
import Loading from "../00_Loading/Loading";
import Informes from "./01_Informes";

const initDate = async() => {
    try {
        const dataFarmacias = await axios({
            method:'GET',
            url: `${GetDetalleGuardiasByFarmacia}`,
            auth: {
                username: credencialesNav.username,
                password: credencialesNav.password
            }
        })
        console.log(dataFarmacias.data)
        return {
            data: dataFarmacias.data.data
        }
        
    } catch (error) {
        return false
    }
}

const IndexInformes = (props) => {

    const [loading, setLoading] = useState(true)
    const [dataFarmacias, setDataFarmacias] = useState([])

    useEffect(() => {
        const getData = async () => {
            const getDataFarmacias = await initDate()
            setDataFarmacias(getDataFarmacias.data)
            setLoading(false)
        }
        getData()
    },[])



    if(loading){
        return (
            <Loading />
        )
    }

    return (
        <div className='row'>
            <Navegacion  data={props.data.Navegacion} active={props.seccion} />
    
            <div className="col-md-10">
                <NavSuperior data={props.data.NavegacionSuperior} seccion="Informes" />
                <Informes data={props.data.Informes} detalle={dataFarmacias}  />
            </div>
        </div>
    )
}

export default IndexInformes
