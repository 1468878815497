import React, { useState, useEffect, } from "react";
import { useParams } from "react-router";
import axios from "axios";

import { credencialesNav, GetCronoMensual } from "../../services/services";

import Loading from "../00_Loading/Loading";
import Navegacion from "../00_Navigation/Index";
import NavSuperior from "../00_NavAux/Index";
import InfoCrono from "./01_InfoCrono";
import CardPanel from "./02_CardPanel";
import CronoNotFound from "./04_CronoNoFound";

const initDate = async(Mes, Ano) => {
    try {
        const dataCrono = await axios({
            method:'GET',
            url: `${GetCronoMensual}/${Ano}/${Mes}`,
            auth: {
                username: credencialesNav.username,
                password: credencialesNav.password
            }
        })

        return {
            data: dataCrono.data.data
        }
        
    } catch (error) {
        return false
    }
}



const DetalleCronograma = (props) => {

    const {Mes} = useParams()
    const {Ano} = useParams()

    const [cronograma, setCrono] = useState([])
    const [loading, setLoading] = useState(true)
    const [errorCrono, setErrorCrono] = useState(false)

    useEffect(() => {
        const getData = async () => {
            const getCrono = await initDate(Mes, Ano)
            console.log(getCrono.length)
            if(getCrono.data.length > 0){
                setCrono(getCrono.data)
               
            }else{
                setErrorCrono(true)
            }
            setLoading(false)
        }
        getData()
    },[Mes, Ano])



    if(loading){
        return (
            <Loading />
        )
    }



    return (

        <div className='row'>
            <Navegacion  data={props.data.Navegacion} active={props.seccion} />

        <div className="col-md-10">
            <NavSuperior data={props.data.NavegacionSuperior} seccion="Detalle Cronograma" />
            <InfoCrono data={props.data.Cronogramas} mes={Mes} ano={Ano} />
            {
                errorCrono === true ?
                <CronoNotFound data={props.data.Cronogramas} />
                :
                <CardPanel cronograma={cronograma} />
            }
           
        </div>
        
    </div>
    )
}

export default DetalleCronograma