import React, {useState, useEffect} from "react";
import styled from 'styled-components';
import axios from "axios";
import Cookies from "universal-cookie";


import { credencialesNav, AuthAdmin } from "../../services/services";
import Loading from "../00_Loading/Loading";

const cookies = new Cookies();
const cambiarEstado = (funcion) => {
    setTimeout(()=> {
      funcion(false)
    }, 600)
}


const Login = (props) => {

    const [loading, setLoading] = useState(true)
    const [errorDatos, setErrorDatos] = useState(false)
    const [userName, setUsername] = useState('')
    const [pass, setPass] = useState('')

    useEffect(()=>{
        if(cookies.get('admin') === 'Bragado - 5sPgIIdSfh'){
            window.location.href="./Inicio";
        }
        cambiarEstado(setLoading)
    },[])


    const onSubmit = async(e) => {
        e.preventDefault()
        setLoading(true)
        const body = {
            "Username": userName,
            "Password": pass
        }

        try {
            const data = await axios({
                method: 'POST',
                url: `${AuthAdmin}`,
                data: body,
                auth: {
                    username: credencialesNav.username,
                    password: credencialesNav.password
                }
            })
           
            if(data.data.status === 'OK'){
                const res = data.data.data
                cookies.set("admin", res , { path: "/" });
                window.location.href = "./Inicio";
            }else{
                setLoading(false)
                setErrorDatos(true)
            }
            
        } catch (error) {
            setLoading(false)
            setErrorDatos(true)
        }


    }


    if(loading){
        return (
            <Loading />
        )
    }

    return (
        <ContentGeneralLogin>
            <ContentFormLogin>
                <TituloLogin>
                    {props.data.LoginBienvenidos}
                </TituloLogin>
                <TituloLogin>
                    {props.data.FarmaciasBragado}
                </TituloLogin>
                <SubTituloLogin>
                    {props.data.LoginIngresaDatos}
                </SubTituloLogin>
                <div className="row mt-4">
                    <div className="col-md-12 col-12 mx-auto">
                        <form onSubmit={onSubmit}>
                            <div className='mb-3'>
                                <InputFormLogin 
                                    className='form-control'
                                    placeholder={props.data.LoginPlaceHolderUsuario}
                                    type='text'
                                    name='usuario'
                                    value={userName}
                                    onChange={ (e) => setUsername(e.target.value)} 
                                    required
                                />

                            </div>
                            <div>
                                <InputFormLogin 
                                    className='form-control'
                                    placeholder={props.data.LoginPlaceHolderContra}
                                    type='password'
                                    name='password'
                                    value={pass}
                                    onChange={ (e) => setPass(e.target.value)} 
                                    required
                                />
                            </div>
                            <div className="mb-3">
     
                                    {
                                        errorDatos === true &&
                                        <TextoErrorEnLogin>
                                            {props.data.ErrorLogin}
                                        </TextoErrorEnLogin>

                                    }
                                   
                                
                                
                            </div>
                            
                            <div className='mb-3'>
                                <BotonIngresar type="submit">
                                    {props.data.IniciaSesion}
                                </BotonIngresar>

                            </div>

                        </form>
                    </div>
                </div>

            </ContentFormLogin>
            


                    
        </ContentGeneralLogin>

    )
}

export default Login

const ContentGeneralLogin = styled.div`
    height:100vh;
    background: linear-gradient(180deg, #EEFFF0 0%, #FFFFFF 87.5%, rgba(255, 255, 255, 0) 96.87%);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

`
const ContentFormLogin = styled.div`
    height: 60vh;
    margin: auto;
    width: 30%;
    @media (max-width: 480px) {
        width: 80%;
    }
`

const TituloLogin = styled.p`
    font-family: var(--Pro-Text-Regular);
    font-weight: 600;
    font-size: 1.75rem;
    text-align: center;
    @media (max-width: 480px) {
        font-size: 1.5rem;
    }
`

const SubTituloLogin = styled.p`
    font-family: var(--Pro-Text-Regular);
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 25px;
    color: #00713B;
    @media (max-width: 480px) {
        font-size: .9rem;
    }
`

const InputFormLogin = styled.input`
    border-radius: 10px !important;
    width: 100%;
    padding: 10px;
    transition: all .5s linear !important;

    &::placeholder {
        font-family: var(--Pro-Text-Regular);
        color: #808080 !important;
        font-size: .9rem;
        padding-left: 10px;
    }

    &:focus{
        border: #00713B !important;
        color: #00713B  !important;
        box-shadow: 1px 1px 1px 1px #00713B !important;
        transform: scale(.9);
    }
`

const BotonIngresar = styled.button`
  background-color: #00713B;
  width: 50%;
  border-radius: 20px;
  border: none;
  color: var(--Color-White);
  font-family: var(--Pro-Text-Regular);
  padding: 10px;
  box-shadow: 0px 2px 10px 10px rgba(5, 13, 39, .1);
  transition: all 0.2s linear;
  &:hover {
    color: #00713B;
    background: transparent;
    box-shadow: none;
    transform: scale(.9);
  }

  @media (max-width: 480px) {
        width: 70%;
    }

`;

const TextoErrorEnLogin = styled.p`
    font-family: var(--Pro-Text-Regular);
    text-align: center;
    margin-top: 2%;
    color: var(--Color-Rojo);
    font-size: .8rem;
`
