import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const BarraSuperior = (props) => {
    return (
        <ContentNavSuperior className="row">

                <DivItemNav className="col-md-1 text-end">
                    <Link to='/Inicio'>
                        <ItemNavIncio>
                            {props.data.Inicio} 
                        </ItemNavIncio>
                        <IconoDiv  className="bi bi-chevron-right" />
                    </Link>

                </DivItemNav>
                <DivItemNav className="col-md-6">
                <Seccion>
                        {props.seccion}
                    </Seccion>

                    
                </DivItemNav>



        </ContentNavSuperior>
    )
}

export default BarraSuperior

const ContentNavSuperior = styled.div`
    max-width: 100%;
    margin-left: -1% !important;
    background: #BFE8C3;
    padding-left: 0!important;
    padding-right: 0!important;
    padding-top: 20px;
    padding-bottom: 20px;
`
const DivItemNav = styled.div`
    padding-left: 0!important;
    padding-right: 0!important;
`

const ItemNavIncio = styled.span`
    color: #707D77;
    font-family: var(--Pro-Text-Regular);
    margin-right: 5px ;

    &:hover{
        color: #fff;
    }
`

const IconoDiv = styled.i`
    color: #707D77;
`

const Seccion = styled.p`
    margin-left: 10px;
    font-family: var(--Pro-Text-Regular);
    font-weight: 600;
`
