import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import CardGuardia from "./02_CardGuardia";

const GuardiasDelDia = (props) => {

    return (
        <ContentGralGuardiasDelDia>
            <TituloSeccion>
                {props.data.Titulo}
            </TituloSeccion>
            <SubtituloSeccion>
                {props.data.SubTitulo}
                {props.guardias.fecha}.
                <br />
                {props.data.SubTitulo2}
            </SubtituloSeccion>
            <ContentGuardias className='row'>
                {
                    props.guardias.detalle.map(guardia => (
                        <CardGuardia data={props.data} guardia={guardia} key={guardia.Id} />
                    ))
                }

            </ContentGuardias>

            <ContentGuardias className='row'>
                <div className="col-md-4">
                    <Link to='/Cambios'>
                        <BotonVolver>
                            {props.data.BtnVerVolver}
                        </BotonVolver>
                    </Link>
                </div>
                <div className="col-md-4">
                    <Link to='/Inicio'>
                        <BotonVolverAlInicio>
                            {props.data.BtnVolverInicio}
                        </BotonVolverAlInicio>
                    </Link>
                </div>


            </ContentGuardias>




        </ContentGralGuardiasDelDia>
    )
}

export default GuardiasDelDia


const ContentGralGuardiasDelDia = styled.div`
    background: linear-gradient(180deg, #EEFFF0 0%, #FFFFFF 87.5%, rgba(255, 255, 255, 0) 96.87%);
    padding: 50px 30px;
`

const TituloSeccion = styled.p`
    font-family: var(--Pro-Text-Regular);
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 5%;
`

const SubtituloSeccion = styled.p`
    font-family: var(--Pro-Text-Regular);
    font-size: 1rem;
    margin-left: 5%;
    color: #00713B;
    margin-top: 10px;
`

const ContentGuardias = styled.div`
    margin: 5% !important;
`

const BotonVolverAlInicio = styled.button`
    background: #BFE8C3;
    font-family: var(--Pro-Text-Regular);
    color: #00713B;
    width: 80%;
    margin-left: 0%;
    padding: 10px 33px;
    border: none;
    border-radius: 12px;
    transition: all .5s linear;
    font-size: .9rem;
    &:hover{
        transform: scale(.9);
        background-color: #BFE8C3;
        color: #38A342;
    }

`

const BotonVolver = styled.button`
    background: #FFFFFF;
    font-family: var(--Pro-Text-Regular);
    color: #00713B;
    width: 80%;
    margin-left: 0%;
    padding: 10px 33px;
    border: 1px solid #BFE8C3;
    border-radius: 12px;
    transition: all .5s linear;
    font-size: .9rem;
    &:hover{
        transform: scale(.9);
        background-color: #BFE8C3;
        color: #38A342;
    }
`
