import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import axios from "axios";
import './App.css';

import Propiedades from "./locales/dataGeneral.json";
import { credencialesNav,InitApi } from "./services/services";

import Loading from "./components/00_Loading/Loading";
import Errors from "./components/00_Errors/Index";

import Login from "./components/01_FormLogin/Index";
import Inicio from "./components/02_Inicio/Index";
import Farmacias from "./components/03_Farmacias/Index";
import DetalleFarmacia from "./components/04_FarmaciasDetalle/Index";
import Cronogramas from "./components/05_Cronogramas/Index";
import DetalleCronograma from "./components/06_CronogramasDetalle/Index";
import GuardiasDelDia from "./components/07_Guardias/Index";
import DetalleGuardia from "./components/08_GuardiasDetalle/Index";
import CambioGuardia from "./components/09_CambiarGuardias/Index";
import CambioGuardiaDetalle from "./components/10_CambiarGuardiasDetalle/Index";
import Informes from "./components/11_Informes/Index";



class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      conectAPI : true
    }
  }

  componentDidMount(){


    const data = async () => {
      try {
        await axios({
          method:'GET',
          url: InitApi,
          auth: credencialesNav
        })
        this.setState({loading: false})
        return
        
      } catch (error) {
        this.setState({loading: false})
        this.setState({conectAPI: false})
        return
      }
    }

    data()



  }

  render() {

    if(this.state.loading){
      return (
        <Loading />
      )
    }

    if(!this.state.conectAPI){
      return (
        <Errors data={Propiedades.Errores} Api={this.state.conectAPI}  />
  
      )
    }

    return(
      <Router>
        <Switch>
          {/* Pantalla de Login */}
          <Route path='/' exact>
            <Login data={Propiedades.Inicio} />
          </Route>

          {/* Pantalla de Inicio */}
          <Route path='/Inicio' exact>
            <Inicio data={Propiedades} />
          </Route>
          {/* Pantalla de Farmacias */}
          <Route path='/Farmacias' exact>
            <Farmacias data={Propiedades} seccion='Farmacias' />
          </Route>
          {/* Pantalla Detalle de una Farmacia */}
          <Route path='/Farmacias/:Id' exact>
            <DetalleFarmacia data={Propiedades} seccion='Farmacias' />
          </Route>
          {/* Pantalla Para Obtener un Cronograma */}
          <Route path='/Cronogramas' exact>
            <Cronogramas data={Propiedades} seccion='Cronogramas' />
          </Route>
          {/* Pantalla Para Detalle de un Cronograma */}
          <Route path='/Cronogramas/:Mes/:Ano' exact>
            <DetalleCronograma data={Propiedades} seccion='Cronogramas'/>
          </Route>

          {/* Pantalla para ver las guardias de un Dia Determinado */}
          <Route path='/Guardias/:Fecha' exact>
            <GuardiasDelDia data={Propiedades}  seccion='Cambios' />
          </Route>

          {/* Pantalla Para Obtener Detalles de una Guardia */}
          <Route path='/Guardia/:Id' exact>
            <DetalleGuardia data={Propiedades} />
          </Route>
          {/* Pantalla Inicial para buscar Cambios de Guardias */}
          <Route path='/Cambios' exact>
            <CambioGuardia data={Propiedades} seccion='Cambios' />
          </Route>
          {/* Pantalla para realizar un cambio de guardia */}
          <Route path='/CambiarGuardia/:Id' exact>
            <CambioGuardiaDetalle data={Propiedades} seccion='Cambios' />
          </Route>
          {/* Pantalla Inicial de Informes */}
          <Route path='/Informes' exact>
            <Informes data={Propiedades} seccion='Informes' />
          </Route>
          {/* Pantalla De Error */}
          <Route path={'*'}>
            <Errors data={Propiedades} Api={this.state.conectAPI}  />
          </Route>


        </Switch>
      </Router>
    )

  }

}

export default App;
