import React, { useState, useEffect, } from "react";
import { useParams } from "react-router";
import axios from "axios";

import { credencialesNav, GetGuardiasDelDia } from "../../services/services";

import Loading from "../00_Loading/Loading";
import Navegacion from "../00_Navigation/Index";
import NavSuperior from "../00_NavAux/Index";
import GuardiasDelDia from "./01_GuardiasDelDia";


const initDate = async(Fecha) => {
    try {
        const dataGuardias = await axios({
            method:'GET',
            url: `${GetGuardiasDelDia}/${Fecha}`,
            auth: {
                username: credencialesNav.username,
                password: credencialesNav.password
            }
        })

        return {
            data: dataGuardias.data.data
        }
        
    } catch (error) {
        return false
    }
}

const IndexGuardiasDelDia = (props) => {

    const {Fecha} = useParams()

    const [guardias, setGuardias] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getData = async () => {
            const getGuardias = await initDate(Fecha)
            setGuardias(getGuardias.data)
            setLoading(false)
        }
        getData()
    },[Fecha])

    if(loading){
        return (
            <Loading />
        )
    }


    return (
        <div className='row'>
           
            <Navegacion  data={props.data.Navegacion} active={props.seccion} />
        
            <div className="col-md-10">
                <NavSuperior data={props.data.NavegacionSuperior} seccion={`Guardias del dia ${Fecha}`} />
                <GuardiasDelDia data={props.data.GuardiasDelDia}  guardias={guardias} />

            </div>
            
        </div>
    )
}

export default IndexGuardiasDelDia