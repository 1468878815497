import React from 'react'

import Navegacion from "./01_NavBar";

export const IndexNavegacion = (props) => {
    return (
        <Navegacion data={props.data} active={props.active} />
    )
}

export default IndexNavegacion
