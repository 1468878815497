import styled from 'styled-components';
import CardGuardia from "./03_CardsGuardias";


const CardPanel = (props) => {

    return (
        <ContentCronograma className="row">
            {
                props.cronograma.map(undia => (
                    <div className="col-md-3 mt-2 mb-2" key={undia.dia}>
                        <ContentFecha>
                            {undia.fecha}
                        </ContentFecha>
                        {
                            undia.guardias.map(guardia => (
                                <CardGuardia key={guardia.Id} detalle={guardia} />
                            ))
                        }

                        <hr />
                        <br />

                    </div>
                    
                ))
            }


        <hr />


        </ContentCronograma>



    )
}

export default CardPanel

const ContentCronograma = styled.div`
    width: 95% !important;
    margin: 2.5% !important;
`

const ContentFecha = styled.div`
    background: #00713B;
    box-shadow: 0px 4px 18px 0px #00000040;
    border-radius: 31px;
    padding: 10px;
    font-family: var(--Pro-Text-Regular);
    font-size: .7rem;
    color: #fff;
    text-align: center;
    width: 80%;
    margin: auto;
    margin-bottom: 5%;
`