import React from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components';


const ModalError = (props) => {

    return (
        <div key='contentModal'>
            <Modal isOpen={props.Modal} >
                <ModalHeader className='text-center' >
                    <Titulo>
                        {props.Titulo} 
                    </Titulo>
                    <SubTitulo>
                        {props.Subtitulo} 
                        {props.Detail}.
                    </SubTitulo>
                    
                </ModalHeader>
                <ModalBody className='w-100'>
                    <TextoInfo>
                        {props.Info}
                    </TextoInfo>
                   
                    
                </ModalBody>
                <ModalFooter  className='w-100'>
                <div className="row">
                        <div className="col-md-6 mx-auto">
                            <Link to='/Inicio'>
                                <BotonVolverAlInicio>
                                    Volver al inicio
                                </BotonVolverAlInicio>
                            </Link>
                        </div>

                        <div className="col-md-6 mx-auto">
                            <Link to={props.Link}>
                                <BotonIrAOpcion>
                                    {props.TextoLink}
                                </BotonIrAOpcion>
                            </Link>
                        </div>
                    </div>


                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ModalError;

const Titulo = styled.p `
    color: #c0392b;
    font-size: 1.5rem;
    font-family: var(--Pro-Text-Regular);
    margin-bottom: 10px !important;
`

const SubTitulo = styled.p `
    color: #c0392b;
    font-size: 1rem;
    font-family: var(--Pro-Text-Regular);
`

const TextoInfo = styled.p`
    font-family: var(--Pro-Text-Regular);
    color: #808080;
    width: 90%;
    margin-left: 5%;
    text-align: center;
`

const BotonVolverAlInicio = styled.button`
    background: #BFE8C3;
    font-family: var(--Pro-Text-Regular);
    color: #00713B;
    width: 80%;
    margin-left: 10%;
    font-size: .8rem;
    padding: 10px 33px;
    border: none;
    border-radius: 12px;
    transition: all .5s linear;
    &:hover{
        transform: scale(.9);
        background-color: #BFE8C3;
        color: #38A342;
    }

`

const BotonIrAOpcion = styled.button`
    background: #FFFFFF;
    font-family: var(--Pro-Text-Regular);
    color: #00713B;
    width: 80%;
    margin-left: 10%;
    font-size: .8rem;
    padding: 10px 33px;
    border: 1px solid #BFE8C3;
    border-radius: 12px;
    transition: all .5s linear;
    &:hover{
        transform: scale(.9);
        background-color: #BFE8C3;
        color: #38A342;
    }
`