import { Link } from 'react-router-dom';
import styled from 'styled-components';

const CronoNotFound = (props) => {
    return (
        <ContentMensajeNotFound>
            <MsgNotFound>
                {props.data.MsgNotFound}
            </MsgNotFound>
            <br />
            <div className="row">
                <div className="col-md-4 mx-auto">
                    <Link to='/Cronogramas'>
                        <BotonIrACrono>
                            {props.data.BtnBuscarOtro}
                        </BotonIrACrono>

                    </Link>
                </div>
            </div>

        </ContentMensajeNotFound>

    )
}

export default CronoNotFound

const ContentMensajeNotFound = styled.div`
    text-align: center;
    margin-top: 7%;
`

const MsgNotFound = styled.p`
    font-family: var(--Pro-Text-Regular);
`

const BotonIrACrono = styled.button`
    background: #00713B;
    font-family: var(--Pro-Text-Regular);
    color: #fff;
    width: 60%;
    text-align: center;
    font-size: .8rem;
    padding: 15px;
    border: none;
    border-radius: 10px;
    transition: all .5s linear;
    &:hover{
        transform: scale(.9);
        background-color: #BFE8C3;
        color: #38A342;
    }
`