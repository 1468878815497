const stateApi = 'D'

let rutaInicial = ''

export const credencialesNav = {
    username: 'FarmBragadoAPIDev',
    password: 'FarmBragadoAPIDev!'
}

// if(stateApi === 'D'){
//     rutaInicial= 'http://localhost:3001/api_v1'
// }


if(stateApi === 'D'){
    rutaInicial= 'https://api.farmaciasbragado.com.ar/api_v1'
}

//Chequea estado de la API
export const InitApi = `${rutaInicial}/admin/init`

//Login de Usuario
export const AuthAdmin = `${rutaInicial}/admin/login`

//Obtiene todas las Farmacias
export const GetAllFarmacias = `${rutaInicial}/farmacias/getAll`

//Obtiene el detalle de Farmacias de Guardia por los proximos 7 dias.
export const GetNextGuardias = `${rutaInicial}/guardias/getNextDays`

//Obtiene los detalles de una Farmacia
export const GetDataFarmacia = `${rutaInicial}/farmacias/getOne`

//Obtiene un Cronograma Mensual de Guardias
export const GetCronoMensual = `${rutaInicial}/guardias/getCronograma`

//Obtiene las Guardias de un Dia Especifico
export const GetGuardiasDelDia = `${rutaInicial}/guardias/getDay`

//Obtiene un Detalle de una Guardia
export const GetDetalleGuardia = `${rutaInicial}/guardias/getGuardia`

//Obtiene la Informacion para el cambio de una Guardia
export const GetInfoCambioGuardia = `${rutaInicial}/cambioGuardia/getInfo`

//Ruta para Editar una Guardia
export const EditGuardia = `${rutaInicial}/cambioGuardia`

//Ruta que Obtiene el detalles de guardias que hacen las Farmacias
export const GetDetalleGuardiasByFarmacia = `${rutaInicial}/informes/getDetalleGuardias`
