import { Link } from 'react-router-dom';
import styled from 'styled-components';

const CardsFarmacias = (props) => {

    return (
        <CardFarmacia  className='col-md-4'>
            <ContentNombreFarmacia>
                <NombreFarmacia>
                    {props.farmacia.nombre}
                </NombreFarmacia> 
            </ContentNombreFarmacia>

                <ContentInfoGuardia>
                    <TextoInfoGuardia>
                        <IconosInfoGuardia className='bi bi-clock' />
                        Cantidad de Guardias: <strong>{props.farmacia.totalGuardias}</strong> 
                    </TextoInfoGuardia>

                    <TextoInfoGuardia>
                        <IconosInfoGuardia className='bi bi-bookmark-check' />
                        Guardias 24hs: <strong>{props.farmacia.guardias24}</strong> 
                    </TextoInfoGuardia>
                    
                    <TextoInfoGuardia>
                        <IconosInfoGuardia className='bi bi-bookmark-check' />
                        Guardias 16hs: <strong>{props.farmacia.guardias16}</strong> 
                    </TextoInfoGuardia>
                    
                    <TextoInfoGuardia>
                        <IconosInfoGuardia className='bi bi-bookmark-check' />
                        Guardias 14hs: <strong>{props.farmacia.guardias14}</strong> 
                    </TextoInfoGuardia>

                    <Link to={`/Farmacias/${props.farmacia.idFarmacia}`}>
                        <BotonEditarFarmacia>
                            Ver info Farmacia
                        </BotonEditarFarmacia>
                    </Link>

                </ContentInfoGuardia>

        </CardFarmacia>
                   

        
    )
}

export default CardsFarmacias

const CardFarmacia = styled.div`
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid var(--Color-Verde-Border);
    box-shadow: 2px 15px 61px 0px rgba(5,13,39,0.34);
    padding: 10px;
    width:30%;
    margin: auto;
    margin-bottom: 2%;
    @media (max-width: 480px) {
        width:100%;
        margin: 2%;
    }
`

const ContentNombreFarmacia = styled.div`
    border-bottom: 1px solid var(--Color-Verde-Border);
    padding: 10px 30px ;
    width: 100%;
`

const NombreFarmacia = styled.p`
    font-family: var(--Pro-Text-Regular);
    font-size: .8rem;
    font-weight: bold;
`

const ContentInfoGuardia = styled.div`
    padding: 10px 20px ;

`

const TextoInfoGuardia = styled.p`
    font-family: var(--Pro-Text-Light);
    font-size: .75rem;
    padding: 5px 0px;
`

const IconosInfoGuardia = styled.i`
    color: green;
    padding: 5px;
`



const BotonEditarFarmacia = styled.button`
    background-color: #BFE8C3;
    width: 60%;
    margin-top: 2%;
    margin-left: 40%;
    border-radius: 10px;
    border:none;
    padding: 10px;
    color: #fff;
    font-family: var(--Pro-Text-Regular);
    font-size: .8rem;
    transition: all .5s linear;

    &:hover{
        transform: scale(.9);
        background-color: #BFE8C3;
        color: #38A342;
    }

`
