import { Link } from 'react-router-dom';
import styled from 'styled-components';


const InfoCrono = (props) => {
    const parseMes = parseInt(props.mes) ? parseInt(props.mes) : 0 

    if(parseMes === 0 || parseMes > 12){
        return (
            <h1>Errro</h1>
        )
    }

    const Meses = props.data.Meses
    const result = Meses.filter(mes => mes.id === parseMes )
    const mes = result[0].valor

    return (

        <ContentGralCronograma>
            <div className="row">
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-md-6 align-self-center">
                                <TituloSeccion>
                                    {props.data.Cronograma}
                                </TituloSeccion>
                            </div>
                            <div className="col-md-3">
                                <ContentInfoMesAno>
                                    <TextoInfoMesAno>
                                        <IconoInfoMesAno className='bi bi-calendar2'/>
                                        {props.ano}
                                    </TextoInfoMesAno>
                                </ContentInfoMesAno>
                            </div>
                            <div className="col-md-3">
                            <ContentInfoMesAno>
                                    <TextoInfoMesAno>
                                        <IconoInfoMesAno className='bi bi-calendar2'/>
                                        {mes}

                                    </TextoInfoMesAno>
                                </ContentInfoMesAno>

                            </div>
                        </div>


                    </div>
                    <div className="col-md-4">
                        <Link to='/Inicio'>
                            <BotonIrAlInicio>
                                {props.data.VolverAlInicio}
                            </BotonIrAlInicio>
                        </Link>

                    </div>
                </div>

  
        </ContentGralCronograma>



    )

}

export default InfoCrono

const ContentGralCronograma = styled.div`
    background: #fff;
    padding: 50px 30px;
    border-bottom: 1px solid #808080;
`

const TituloSeccion = styled.p`
    font-family: var(--Pro-Text-Regular);
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 25%;
`

const ContentInfoMesAno = styled.div`
    background: #FFFFFF;
    border: 1px solid #00713B;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 15px;
    width: 70%;
    text-align: center;
    margin: auto;
`

const TextoInfoMesAno = styled.p`
    font-family: var(--Pro-Text-Regular);
    font-size: .8rem;
    font-weight: bold;
`

const IconoInfoMesAno = styled.i`
    color:  #00713B;
    padding-right: 10px;
`

const BotonIrAlInicio = styled.button`
    background: #00713B;
    font-family: var(--Pro-Text-Regular);
    color: #fff;
    width: 60%;
    margin-left: 20%;
    text-align: center;
    font-size: .8rem;
    padding: 15px;
    border: none;
    border-radius: 10px;
    transition: all .5s linear;
    &:hover{
        transform: scale(.9);
        background-color: #BFE8C3;
        color: #38A342;
    }
`