import React from 'react'
import styled from 'styled-components';
import CardsFarmacias from "./02_CardsFarmacias";

const Informes = (props) => {
    return (
        <ContentInformes>
            <TituloSeccion>
                {props.data.Titulo}
            </TituloSeccion>
            <SubtituloSeccion>
                {props.data.SubTitulo}
            </SubtituloSeccion>

            <ContentInfoFarmacias className="row">
                {
                    props.detalle.map(unaFarmacia => (
                        <CardsFarmacias key={unaFarmacia.idFarmacia} farmacia={unaFarmacia} />
                    ))
                }
            </ContentInfoFarmacias>

        </ContentInformes>
    )
}

export default Informes


const ContentInformes = styled.div`
    background: linear-gradient(180deg, #EEFFF0 0%, #FFFFFF 87.5%, rgba(255, 255, 255, 0) 96.87%);
    padding: 50px 30px;
`

const TituloSeccion = styled.p`
    font-family: var(--Pro-Text-Regular);
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 5%;
`

const SubtituloSeccion = styled.p`
    font-family: var(--Pro-Text-Regular);
    font-size: 1rem;
    margin-left: 5%;
    color: #00713B;
`

const ContentInfoFarmacias = styled.div`
    width: 95% !important;
    margin-left: 2.5% !important;
    margin-top: 5% !important
`
