import React, {useEffect} from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Cookies from 'universal-cookie';

import Logo from "../../assets/images/Logo.png";

const cookies = new Cookies();


export const Navegacion = (props) => {

    useEffect(()=>{
        if(cookies.get('admin') !== 'Bragado - 5sPgIIdSfh'){
            window.location.href="./";
        }
        
    },[])

    const cerrarSesion = () => {
        cookies.remove('admin', {path: "/"})
        window.location.href='./';
    }


    return (
        <ContentNavBar className='col-md-2'>

            <ContentLogo>
                <img src={Logo} className='w-100' alt="Logo" />
            </ContentLogo>

            <ContentGralItemNav>
                <ContentItemNav>
                    <Link to={props.data.LinkFarmacias}>
                        {
                            props.active === 'Farmacias' ?
                                <TextoNavegacion>
                                    <Icono className='bi bi-shop' />
                                    {props.data.Farmacias}
                                </TextoNavegacion>
                                :
                                <TextoNavegacionActive>
                                    <Icono className='bi bi-shop' />
                                    {props.data.Farmacias}
                                </TextoNavegacionActive>


                        }
                    </Link>
                </ContentItemNav>

                <ContentItemNav>
                    <Link to={props.data.LinkCronogramas}>
                        {
                            props.active === 'Cronogramas' ?
                                <TextoNavegacion>
                                    <Icono className='bi bi-calendar2' />
                                    {props.data.Cronogramas}
                                </TextoNavegacion>
                                :
                                <TextoNavegacionActive>
                                    <Icono className='bi bi-calendar2' />
                                    {props.data.Cronogramas}
                                </TextoNavegacionActive>
                        }
                    </Link>
                </ContentItemNav>

                <ContentItemNav>
                    <Link to={props.data.LinkInformes}>
                        {
                            props.active === 'Informes' ?
                                <TextoNavegacion>
                                    <Icono className='bi bi-clipboard-data' />
                                    {props.data.Informes}
                                </TextoNavegacion>
                                :
                                <TextoNavegacionActive>
                                    <Icono className='bi bi-clipboard-data' />
                                    {props.data.Informes}

                                </TextoNavegacionActive>
                        }
                    </Link>
                </ContentItemNav>


                <ContentItemNav>
                    <Link to={props.data.LinkCambios}>
                        {
                            props.active === 'Cambios' ?
                                <TextoNavegacion>
                                    <Icono className='bi bi-clock' />
                                    {props.data.Cambios}
                                </TextoNavegacion>
                                :
                                <TextoNavegacionActive>
                                    <Icono className='bi bi-clock' />
                                    {props.data.Cambios}
                                </TextoNavegacionActive>
                        }
                    </Link>
                </ContentItemNav>


                <ContentItemNav>
                    <TextoNavegacionSalir
                        onClick={()=> cerrarSesion()}
                    >
                        <Icono className='bi bi-box-arrow-in-right' />
                        {props.data.CerrarSesion}
                    </TextoNavegacionSalir>

                </ContentItemNav>

            </ContentGralItemNav>



        </ContentNavBar>
    )
}

export default Navegacion

const ContentNavBar = styled.div`;
    min-height: 100vh !important;
    background-color: #00713B;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 50px 30px !important;
`



const ContentLogo = styled.div`
    background-color: #fff;
    border-radius: 50%;
    width: 70%;
    margin: auto;
`

const ContentGralItemNav = styled.ul`
    margin-top: 20%;
    padding: 0 !important;
    width: 100%;
    max-width: 100% !important;
`

const ContentItemNav = styled.li`
  list-style: none;
  padding: 20px 0px;
  border-bottom: 1px solid rgba(100, 100, 100, 1);
  max-width: 100%;
  text-align: left;

`

const TextoNavegacionActive = styled.button`
    font-family: var(--Pro-Text-Regular);
    font-style: normal;
    font-weight: bold;
    font-size: .8rem;
    letter-spacing: .5px;
    width: 100%;
    background: none !important;
    border:none !important;
    color:#333C37;
    text-align: left;
    transition: all .5s linear;

    &:hover{
        color: #fff;
    }
`
const TextoNavegacion = styled.button`
    font-family: var(--Pro-Text-Regular);
    font-style: normal;
    font-weight: bold;
    font-size: .8rem;
    letter-spacing: .5px;
    width: 100%;
    background: none !important;
    border:none !important;
    color:#fff;
    text-align: left;
    transition: all .5s linear;

    &:hover{
        color: #fff;
       
    }
`

const TextoNavegacionSalir = styled.button`
    font-family: var(--Pro-Text-Regular);
    font-style: normal;
    font-size: .8rem;
    letter-spacing: .5px;
    width: 100%;
    background: none !important;
    border:none !important;
    color:#333C37;
    font-weight: bold;
    text-align: left;
    transition: all .5s linear;

    &:hover{
        color: #fff;
       
    }
`

const Icono = styled.i`
    color: #fff;
    margin-right: 10%;
    font-weight: bold;
    font-size: 1rem;
`


