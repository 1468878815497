import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const CardsFarmacias = (props) => {

    return (
        <CardFarmacia className='col-md-4 col-4 mt-2 mb-2  p-4'>


            <div className="row">
                <div className="col-md-2 text-center">
                    <IconosRef className='bi bi-shop' />
                </div>
                <div className="col-md-10">
                    <Etiqueta>
                        Nombre
                    </Etiqueta>

                    <NombreFarmacia>
                        {props.guardia.Farmacias.Nombre}
                    </NombreFarmacia>

                </div>
            </div>
 
            <div className="row">
                <div className="col-md-2">
                    <IconosRef className='bi bi-clock' />
                </div>
                <div className="col-md-10">
                    <div className="row">
                        <div className="col-md-4 p-2">
                            <Etiqueta>
                                Guardia
                            </Etiqueta>
                            <NombreFarmacia>
                                {props.guardia.TipoGuardia.Duracion} Hs.
                            </NombreFarmacia>
                        </div>
                        <div className="col-md-8 align-self-end">
                            <Link to={`/CambiarGuardia/${props.guardia.Id}`} className='w-100'>
                                <BotonCambiarGuardia>
                                    {props.data.BtnCambiaGuardia}
                                </BotonCambiarGuardia>
                            </Link>
                        </div>
                    </div>
                </div>
            </div> 

        </CardFarmacia>
                   

        
    )
}

export default CardsFarmacias

const CardFarmacia = styled.div`
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid var(--Color-Verde-Border);
    box-shadow: 2px 15px 61px 0px rgba(5,13,39,0.34);
    padding: 10px;
    width: 32%;
    margin-right: 1% !important;
    @media (max-width: 480px) {
        width:100%;
        margin: 2%;
    }
`

const IconosRef = styled.i`
    color: #00713B;
    padding: 5px;
    font-size: 1rem;
    font-weight: bold;
`

const Etiqueta = styled.p`
    margin-bottom: 0% !important;
    padding-bottom: 0% !important;
    color: #00713B;
    font-size: .6rem;

`
const NombreFarmacia = styled.p`
    font-family: var(--Pro-Text-Regular);
    font-size: .8rem;
    font-weight: bold;
`

const BotonCambiarGuardia = styled.button`
    background-color: #BFE8C3;
    width: 100%;
    border-radius: 10px;
    border:none;
    padding: 10px;
    color: #fff;
    font-family: var(--Pro-Text-Regular);
    font-size: .75rem;
    transition: all .5s linear;

    &:hover{
        transform: scale(.9);
        background-color: #BFE8C3;
        color: #38A342;
    }

`
