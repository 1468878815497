import React, {useState, useEffect} from 'react'
import axios from "axios";


import { credencialesNav, GetAllFarmacias } from "../../services/services";

import Loading from "../00_Loading/Loading";
import Navegacion from "../00_Navigation/Index";
import NavSuperior from "../00_NavAux/Index";
import Farmacias from "./01_Farmacias";

const initDate = async() => {
    try {
        const dataFarmacias = await axios({
            method:'GET',
            url: `${GetAllFarmacias}`,
            auth: {
                username: credencialesNav.username,
                password: credencialesNav.password
            }
        })

        return {
            data: dataFarmacias.data.data
        }
        
    } catch (error) {
        return false
    }
}




const IndexFarmacias = (props) => {

    const [farmacias, setFarmacias] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getData = async () => {
            const getFarmacias = await initDate()
            setFarmacias(getFarmacias.data)
            setLoading(false)
        }
        getData()
    },[])

    if(loading){
        return (
            <Loading />
        )
    }

    return (
        <div className='row'>
           
            <Navegacion  data={props.data.Navegacion} active={props.seccion} />
        
            <div className="col-md-10">
                <NavSuperior data={props.data.NavegacionSuperior} seccion="Ver Farmacias" />
                <Farmacias farmacias={farmacias} data={props.data.Farmacias} />
            </div>
            
        </div>
    )
}

export default IndexFarmacias


