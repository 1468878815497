import React, {useState} from 'react'

import Navegacion from "../00_Navigation/Index";
import NavSuperior from "../00_NavAux/Index";
import CambioGuardia from "./01_CambioGuardia";
import Loading from "../00_Loading/Loading";

const cambiarEstado = (funcion) => {
    setTimeout(()=> {
      funcion(false)
    }, 600)
}

const IndexBuscaGuardias = (props) => {

    const [loading, setLoading] = useState(true)
    cambiarEstado(setLoading)

    if(loading){
        return (
            <Loading />
        )
    }
    
    return (
        <div className='row'>
            <Navegacion  data={props.data.Navegacion} active={props.seccion} />
    
            <div className="col-md-10">
                <NavSuperior data={props.data.NavegacionSuperior} seccion="Cambios de guardia" />
                <CambioGuardia data={props.data.CambiosDeGuardia} />
            </div>
        </div>
    )
}

export default IndexBuscaGuardias
