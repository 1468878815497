import React, {useState, useEffect}  from "react";
import axios from "axios";

import { credencialesNav, GetNextGuardias } from "../../services/services";

import Loading from "../00_Loading/Loading";

import Navegacion from "../00_Navigation/Index";
import NavSuperior from "../00_NavAux/Index";
import PanelInicio from "../02_Inicio/01_Panel";

const initDate = async() => {
    try {
        const dataGuardias = await axios({
            method:'GET',
            url: `${GetNextGuardias}`,
            auth: {
                username: credencialesNav.username,
                password: credencialesNav.password
            }
        })

        return {
            data: dataGuardias.data.data
        }
        
    } catch (error) {
        return false
    }
}


const Inicio = (props) => {
    const [guardias, setGuardias] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getData = async () => {
            const getGuardias = await initDate()
            setGuardias(getGuardias.data)
            setLoading(false)
        }
        getData()
    },[])

    if(loading){
        return(
            <Loading />
        )
    }


    return (
        <div className="row">

            <Navegacion data={props.data.Navegacion} />

            <div className="col-md-10">
                <NavSuperior data={props.data.NavegacionSuperior}  />
                <PanelInicio data={props.data.PanelInicio} guardias={guardias} />
            </div>
        </div>
    )
}

export default Inicio