import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';


const CambioGuardia = (props) => {
    const today = new Date()
    const Year = today.getFullYear()
    const Month = today.getMonth()+1
    const Day = today.getDate()
    const [Fecha, setFecha] = useState(`${Year}-${Month}-${Day}`)
    const [LinkFecha, setLinkFecha] = useState(`${Day}-${Month}-${Year}`)

    const ChangeDatos = (e) => {
        setFecha(e)
        const data = e
        const arrayData = data.split('-')
        setLinkFecha(`${arrayData[2]}-${arrayData[1]}-${arrayData[0]}`)
    }

    console.log(LinkFecha)


    return (
        <ContentGralCambioGuardia>
            <TituloSeccion>
                {props.data.Titulo}
            </TituloSeccion>
            <SubtituloSeccion>
                {props.data.Subtitulo}
            </SubtituloSeccion>
            <ContentFormBuscaFecha>
                <LabelFecha>
                    {props.data.Fecha}
                </LabelFecha>
                <InputFecha
                    type='date'
                    value={Fecha}
                    min={'2021-10-01'}
                    max={'2031-02-20'}
                    onChange={ (e) => ChangeDatos(e.target.value)} 
                />
                <br />
                <hr />
                <div className="row px-3 mt-4">
                    <div className="col-md-3">
                        <Link to={`/Guardias/${LinkFecha}`}>
                            <BotonVerGuardias>
                                {props.data.BtnVerGuardias}
                            </BotonVerGuardias>
                        </Link>
                    </div>
                    <div className="col-md-3">
                        <Link to={`/Inicio`}>
                            <BotonVolverAlInicio>
                                {props.data.BtnVolverInicio}
                            </BotonVolverAlInicio>
                        </Link>
                    </div>
                </div>
                
            </ContentFormBuscaFecha>
        </ContentGralCambioGuardia>
    )
}

export default CambioGuardia

const ContentGralCambioGuardia = styled.div`
    background: linear-gradient(180deg, #EEFFF0 0%, #FFFFFF 87.5%, rgba(255, 255, 255, 0) 96.87%);
    padding: 50px 30px;
`

const TituloSeccion = styled.p`
    font-family: var(--Pro-Text-Regular);
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 5%;
`

const SubtituloSeccion = styled.p`
    font-family: var(--Pro-Text-Regular);
    font-size: 1rem;
    margin-left: 5%;
    color: #00713B;
    margin-top: 10px;
`

const ContentFormBuscaFecha = styled.div`
    margin: 5%;
`

const LabelFecha = styled.p`
    font-family: var(--Pro-Text-Regular);
    color: #00713B;
    font-size: .75rem;
    margin-left: 1%;
    margin-bottom: 1% !important;
`

const InputFecha = styled.input`
    background: #fff;
    border: 1px solid #00713B;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 15px;
    width: 30%;
`

const BotonVerGuardias = styled.button`
    background: #00713B;
    font-family: var(--Pro-Text-Regular);
    color: #fff;
    width: 90%;
    text-align: center;
    font-size: .8rem;
    padding: 15px;
    border: none;
    border-radius: 10px;
    transition: all .5s linear;
    &:hover{
        transform: scale(.9);
        background-color: #BFE8C3;
        color: #38A342;
    }

`

const BotonVolverAlInicio = styled.button`
    background: #BFE8C3;
    font-family: var(--Pro-Text-Regular);
    color: #00713B;
    width: 90%;
    padding: 15px ;
    border: none;
    border-radius: 10px;
    transition: all .5s linear;
    font-size: .9rem;
    &:hover{
        transform: scale(.9);
        background-color: #BFE8C3;
        color: #38A342;
    }

`
