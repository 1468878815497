import { useState } from "react";
import Navegacion from "../00_Navigation/Index";
import NavSuperior from "../00_NavAux/Index";
import Loading from "../00_Loading/Loading";

import Cronograma from "./01_Cronograma";

const cambiarEstado = (funcion) => {
    setTimeout(()=> {
      funcion(false)
    }, 600)
}

const IndexCronogramas = (props) => {

    const [loading, setLoading] = useState(true)
    cambiarEstado(setLoading)

    if(loading){
        return (
            <Loading />
        )
    }

    return (
        <div className='row'>
           
            <Navegacion  data={props.data.Navegacion} active={props.seccion} />
    
        <div className="col-md-10">
            <NavSuperior data={props.data.NavegacionSuperior} seccion={`${props.seccion}`} />
            <Cronograma data={props.data.Cronogramas} />

        </div>
        
    </div>
    )
}

export default IndexCronogramas