import React, { useState, useEffect, } from "react";
import { useParams } from "react-router";
import axios from "axios";

import Loading from "../00_Loading/Loading";
import Navegacion from "../00_Navigation/Index";
import NavSuperior from "../00_NavAux/Index";
import DetalleFarmacia from "./01_DetalleFarmacia";

import { credencialesNav, GetDataFarmacia } from "../../services/services";

const initDate = async(id) => {
    try {
        const dataFarmacia = await axios({
            method:'GET',
            url: `${GetDataFarmacia}/${id}`,
            auth: {
                username: credencialesNav.username,
                password: credencialesNav.password
            }
        })

        return {
            data: dataFarmacia.data.data
        }
        
    } catch (error) {
        return false
    }
}

const InicioDetalleFarmacia = (props) => {
    const {Id} = useParams()

    const [farmacia, setFarmacia] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getData = async () => {
            const getFarmacia = await initDate(Id)
            setFarmacia(getFarmacia.data)
            setLoading(false)
        }
        getData()
    },[Id])


    if(loading){
        return (
            <Loading />
        )
    }


    return (
        <div className='row'>
           
            <Navegacion  data={props.data.Navegacion} active={props.seccion} />
        
            <div className="col-md-10">
                <NavSuperior data={props.data.NavegacionSuperior} seccion="Detalle Farmacia" />
                <DetalleFarmacia data={props.data.DetalleFarmacia} farmacia={farmacia} />
            </div>
            
        </div>
    )
}

export default InicioDetalleFarmacia