import React, { useState, useEffect, } from "react";
import { useParams } from "react-router";
import axios from "axios";

import { credencialesNav, GetDetalleGuardia } from "../../services/services";


import Loading from "../00_Loading/Loading";
import Navegacion from "../00_Navigation/Index";
import NavSuperior from "../00_NavAux/Index";
import DetalleGuardia from "./01_DetalleGuardia";


const initDate = async(id) => {
    try {
        const dataGuardia = await axios({
            method:'GET',
            url: `${GetDetalleGuardia}/${id}`,
            auth: {
                username: credencialesNav.username,
                password: credencialesNav.password
            }
        })
        return {
            data: dataGuardia.data.data
        }
        
    } catch (error) {
        return false
    }
}

const GuardiaDetalles = (props) => {
    
    const {Id} = useParams()


    const [loading, setLoading] = useState(true)
    const [guardia, setGuardia] = useState([])

    useEffect(() => {
        const getData = async () => {
            const getGuardia = await initDate(Id)
            setGuardia(getGuardia.data)
            setLoading(false)
        }
        getData()
    },[Id])

    console.log(guardia)
    if(loading){
        return (
            <Loading />
        )
    }

    return (
        <div className='row'>
            <Navegacion  data={props.data.Navegacion} active={props.seccion} />
    
            <div className="col-md-10">
                <NavSuperior data={props.data.NavegacionSuperior} seccion="Detalles de la guardia" />
                <DetalleGuardia data={props.data.DetalleGuardia} guardia={guardia} />

            </div>
        </div>
    )
}

export default GuardiaDetalles